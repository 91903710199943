<template>
  <div class="d-flex flex-column gap-2 ">
      <input @change="changeImageValue" type="file" ref="imageInput" name="imageInput" :capture="cameraCapture" id="imageInput" class="d-none">
      <span class="font-14 font-roboto">Bukti Transfer
        <span v-if="optional" class="color-primary">(Opsional)</span>
      </span>
      <template v-if="inputButtonType === 1">
              <label v-if="!imgUrl" for="imageInput" class="button button--sm button--outline-grey gap-2 d-inline-flex align-items-center align-self-start">
                <img src="@/assets/icons/upload.svg" alt="">
                {{label}}
            </label>
       </template>
     <template v-if="inputButtonType === 2">
            <label  v-if="!imgUrl"
                for="imageInput"
                type="button"
                class="btn btn-upload mt-3 d-flex font-12 flex-column align-items-center justify-content-center w-100">
                <img
                    src="@/assets/icons/camera-icon.svg"
                    class="mb-2"
                    alt="upload-icon"
                />
                Pilih gambar untuk upload bukti pembayaran
            </label>
       </template>
         <template v-if="inputButtonType === 3">
         
            <div
                v-if="!imgUrl"
                class="btn btn-upload mt-3 d-flex font-12 flex-column align-items-center justify-content-center w-100"
                 data-bs-toggle="modal" data-bs-target="#selectImageInput" data-bs-dismiss="modal"
                >
                <img
                    src="@/assets/icons/camera-icon.svg"
                    class="mb-2"
                    alt="upload-icon"
                />
                Pilih gambar untuk upload bukti pembayaran
            </div>
            <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#selectImageInput" ref="openModal"></button>
       </template>
       <template  v-if="inputButtonType === 3">
            <div v-if="imgUrl" data-bs-toggle="modal" href="#selectImageInput"  class="input-file d-flex align-items-center justify-content-center" :class="{'dark-overlay' : imgUrl !== ''}" :style="{'background-image': `url(${imgUrl})`}">
                <img src="@/assets/icons/edit.svg" alt="">
            </div>
       </template> 
       <template v-else>
            <label v-if="imgUrl" for="imageInput" class="input-file d-flex align-items-center justify-content-center" :class="{'dark-overlay' : imgUrl !== ''}" :style="{'background-image': `url(${imgUrl})`}">
                <img src="@/assets/icons/edit.svg" alt="">
            </label>
       </template>
    

  </div>
</template>

<script>
export default {
    
    props : {
        label : {
            default : 'Upload Bukti Transfer'
        },
        inputButtonType : {
            default : 1
        },
        optional: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            imgUrl : '',
            cameraCapture: true
        }
    },
    methods : {
        changeImageValue (e) {
            const file = e.target.files[0]
            const img = new Image();
            let fileImage = window.URL.createObjectURL(file)
            img.src = fileImage;
            let reader = new FileReader();
            const _this = this
             reader.onloadend = function() {
                _this.$emit('uploadImage', reader.result)
            }

              reader.readAsDataURL(file);

            img.onload = async () => {
                this.imgUrl = img.src
            }
            e.target.value = null
        },
        clearImage() {
            this.imgUrl = ''
        },
        async openSelectImageInputModal () {
            // this.$emit('closeParentModal')
            setTimeout(this.$refs.openModal.click(), 1000)
            
            // const modal = Modal.getInstance(this.$refs.selectImageInput.$el)
            // console.log(this.$refs.selectImageInput.$el)
            // console.log(modal)
            // modal.show()
        },
        uploadSelected(type) {
            if(type === 'camera'){
                this.$refs.imageInput.setAttribute('capture', '')
                this.$refs.imageInput.click()
            } else {
                this.$refs.imageInput.removeAttribute('capture', '')
                this.cameraCapture = false
                this.$refs.imageInput.click()
            }

          
        }
    }
}
</script>

<style lang="scss" scoped>

.btn-upload {
	min-height: 113px;
	border: 1px dashed #e0e0e0;

	img {
		width: 22px;
		height: 20px;
		object-fit: cover;
	}
}   
.form-label {
    font-size: 14px;
    color: #a0a3bd;
}

.input-file {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: 1px solid #D9DBE9;
    border-radius: 8px;
    max-width: 170px;
    min-height: 260px;

    &.dark-overlay {
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    }

    .input-text {
        font-size: 16px;
        line-height: 26px;
        width: 100%;
    }
}

.bottom-text {
    font-size: 16px;
    font-weight: 400;
    color: #00000098;

}

input {
    display: none;
}
</style>
